import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';

import styles from './release.module.css';

const Wine = ({ image, name, proportions, children }) => (
  <div className={styles.wine}>
    <Img fluid={image} className={styles.wineImage} />
    <h4>{name}</h4>
    {
      proportions && (
        <p className={styles.proportions}>
          {proportions}
        </p>
      )
    }
    <p>{children}</p>
  </div>
);

Wine.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  proportions: PropTypes.string,
  children: PropTypes.node,
};

const ReleasePage = ({ data }) => {
  return (
    <Layout>
      <Section heading="This year's release">
        <p>
          If there was one vintage to describe just how special and unique the
          Yarra Valley can be as a premium cool climate wine producing region,
          2017 would be at the top of the list.
        </p>
        <p>
          This vintage perfectly showcases the elegance and restraint, power
          and energy that wines from the Yarra can exhibit in the very best
          vintages. The 2017 vintage conditions, in some ways, are more akin to
          what we experienced through the 90’s rather than more trends. Perfect
          rainfall was accompanied by warm days and cool nights through most of the
          2017 growing season, leading into a long Indian summer. This particular set
          of weather conditions were exactly what inspired John to establish Mount
          Mary back in 1971.
        </p>
        <p>
          All four wines have benefited remarkably from this mild, long and
          drawn out growing season, where flavours were able to develop slowly
          and reach full ripeness while grapes maintained exceptional acid
          balance, vibrancy and freshness.
        </p>
        <p>
          These new wines all exhibit such energy, purity and clarity of fruit
          that reflect so strongly our site, region and the 2017 season.
        </p>
        <div className={styles.wineRow}>
          <Wine
            image={data.quintetImage.childImageSharp.fluid}
            name="2017 Quintet"
            proportions="45% CABERNET SAUVIGNON, 30% MERLOT, 16% CABERNET FRANC, 5% MALBEC, 4% PETIT VERDOT"
          >
            Our Quintet blend remains the flagship wine of our estate,
            representing the initial vision Dr John Middleton had when
            discovering the unique site that is Mount Mary. It’s hard to remember
            a Quintet that displayed such lifted aromatics on release as this
            2017 vintage. The nose is particularly vibrant and fruit forward at
            this early stage, bursting with aromas of cassis, cherry, plum and
            chocolate. There is a hint of earth/undergrowth/cigarbox here playing
            second fiddle to the bright expressive fruit. The palate follows on
            with a similar theme with flavours of blackcurrant, plum, liquorice
            and chocolate. The tannins and acid profile give this wine such
            beautiful structure, purity and persistence through the palate which
            we have not seen in a young Quintet for quite some time. We are
            extremely excited to see how this wine will evolve over the coming
            2-3 decades.
          </Wine>
          <Wine
            image={data.pinotImage.childImageSharp.fluid}
            name="2017 Pinot noir"
          >
            John planted Pinot noir at Mount Mary in 1971. The original block is
            1.2 acres in size and contains over 20 different Pinot clones. The
            clonal diversity provides such complexity and uniqueness to this wine.
            Put simply, there has never been a better year in the Yarra Valley for
            Pinot noir than 2017. The nose opens with dominant primary fruit but
            reveals so many other complexities as the wine breathes in the glass.
            Stewed strawberries, raspberry, cherry and quince make way to beetroot,
            hints of marzipan, spice and dark chocolate. The palate is medium bodied
            but is defined by the underlying intensity and length of flavour. There is
            a fine grain of tannin that runs confidently through this wine, building
            power while also adding to the savoury characteristics. With its best years
            still well and truly ahead of it, this is a wine set to age beautifully in
            the cellar for another decade or more.
          </Wine>
        </div>
        <div className={styles.wineRow}>
          <Wine
            image={data.chardonnayImage.childImageSharp.fluid}
            name="2017 Chardonnay"
          >
            Chardonnay remains the premier white grape of the Yarra Valley. In
            seasons like 2017, we believe it can rival some of the best
            Chardonnays of the world. Our newest Chardonnay blocks are now
            approaching 10 years of age, adding an extra layer of clonal
            diversity to this wine when blended with our older plantings (now
            reaching 35-45 years of age). The nose of the 2017 Chardonnay opens
            with citrus characters, lemon curd, some riper melon nuances, along
            with struck match, toast, and almond meal. The palate is fine,
            elegant and pure with flavours of citrus, grapefruit, honey and some
            very appealing toasty oak complexities. The defining feature of this
            wine on release is the pristine acid line and tremendous length.
            While the palate shows ample generosity now, expect this wine to
            build concentration over the next few years as it relaxes a little in
            bottle.
          </Wine>
          <Wine
            image={data.trioletImage.childImageSharp.fluid}
            name="2017 Triolet"
            proportions="67% SAUVIGNON BLANC, 23% SEMILLON, 10% MUSCADELLE"
          >
            The 2017 Triolet speaks so clearly of Dr John Middleton’s ideals and
            vision for this blend. It is a tighter, more restrained example than
            2016, while displaying so much underlying power and concentration.
            The nose shows a vast array of fruit including lemon/lime, honeydew
            and just-ripe pear. These fruit characteristics are combined with
            secondary flavours of oyster shell, seashore and brine elements which
            all add complexity. The palate is medium bodied and displays a
            beautiful natural acid profile that is matched with an appealing,
            juicy fruit expression of lychee, melon and red apple. There are
            phenolics present, as is always the case with our white wines at
            Mount Mary, but they are so fine grained and well integrated.
          </Wine>
        </div>
      </Section>
      <Pagination
        forwardLink="/members-table"
        backLink="/marli-russell"
      />
    </Layout>
  );
};

ReleasePage.propTypes = {
  data: PropTypes.shape({
    quintetImage: PropTypes.object,
    pinotImage: PropTypes.object,
    trioletImage: PropTypes.object,
    chardonnayImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    quintetImage: file(relativePath: { eq: "QUINTET.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinotImage: file(relativePath: { eq: "PN.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trioletImage: file(relativePath: { eq: "TRIOLET.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chardonnayImage: file(relativePath: { eq: "CHARDONNAY.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ReleasePage;
